<template>

        <div class="p-4 mt-4 bg-white rounded shadow">

 <div v-show="loading" class="w-full max-w-xs mt-32 mb-12">
<div class="flex items-center justify-center ">
    <div class="w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin"></div>
    </div>
</div>

        <div v-if="!loading">
            <div v-if="!showJoined">
                <div>
                    Become a member - provide a reason
                </div>
                <div class="flex items-center justify-between">


                        <div class="w-8 mt-4" v-if="authUser">
                            <img :src="authUser.avatarUri"
                            class="object-cover w-8 h-8 rounded-full">
                        </div>

                        <div class="flex flex-1 mx-4">

                            <textarea v-model="postMessage" name="body" class="w-full pl-4 bg-gray-200 h-36" :placeholder="$t('message.reason')" />
                            <button v-if="postMessage" @click="dispatchJoinRequest();" class="h-8 px-2 py-1 ml-2 bg-gray-200 rounded-full">{{ $t('message.send') }}</button>
                        </div>

                </div>
            </div>
            </div>
            <div v-if="showJoined">
                <div >
                    {{ $t('message.joinsuccess') }}
                </div>
        </div>
        </div>

</template>

<script>
import { mapGetters } from 'vuex';
export default {
    components: {
    },
    data: function () {
    return {
        postMessage : '',
      loading : false,
      showJoined : false
    }
    },
    methods: {

        dispatchJoinRequest() {
            this.$store.dispatch('postGroupJoinRequest', { postMessage: this.postMessage, groupid: this.$route.params.id, that : this });
        }
    },
    name: "NewJoinRequest",
    computed: {


        ...mapGetters({
            authUser: 'authUser',
        }),

    }

}
</script>

<style>
.dz-upload {
    display: block;
    background-color: red;
    height: 10px;
    width: 0%;
}
 .fade-enter-active, .fade-leave-active {
      transition: opacity 2s
   }
   .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0
   }
</style>