<template>
        <div class="p-4 mt-4 bg-white rounded shadow ">
 <div v-show="loading" class="w-full max-w-xs mt-32 mb-12">
<div class="flex items-center justify-center ">
    <div class="w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin"></div>
    </div>
</div>

        <div v-if="!loading">
                <div class="flex items-center justify-between">
                        <div>
                            <div class="w-8" v-if="authUser">
                                <router-link :to="'/'+authUser.userName">
                                <img :src="authUser.avatarUri" class="object-cover w-8 h-8 rounded-full">
                                </router-link>

                            </div>
                        </div>
                        <div class="flex flex-1 mx-4">
                            <input type="text" v-model="postGroupMessage" name="body" class="w-full h-8 pl-4 bg-gray-200 rounded-full" :placeholder="$t('message.addapost')">
                            <button v-if="postGroupMessage" @click="dispatchPostGroupMessage();" class="px-2 py-1 ml-2 bg-gray-200 rounded-full">{{ $t('message.dopost') }}</button>
                        </div>

                </div>
                <div class="m-4">
                    <vue-dropzone  v-on:vdropzone-upload-progress="sending" v-on:vdropzone-s3-upload-error="s3UploadError"
        v-on:vdropzone-s3-upload-success="s3UploadSuccess" :awss3="awss3" @vdropzone-success="added" ref="myVueDropzone" id="dropzone" :options="dropzoneOptions"></vue-dropzone>
                </div>
                <div>
                    {{ $t('message.totalkb') }}: {{ totalKB }} Kilobytes
                </div>
            </div>
        </div>
</template>

<script>
import { mapGetters } from 'vuex';
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
export default {
    components: {
        vueDropzone: vue2Dropzone
    },
    data: function () {
    return {
      loading : false,
      totalKB: 0,
      dropzoneOptions: {
          url: '/file-upload',
          maxFiles: 1,
          acceptedFiles: 'image/png,image/jpg,image/gif,image/jpeg,video/mp4',
          dictDefaultMessage: this.$t('message.dropzone'),
      },
      awss3: {
      signingURL: '/signature', //Where you will get signed url
      headers: {},
      params : {},
      sendFileToServer :false //If you want to upload file to your server along with s3
        }
    }
    },
    methods: {
        sending(file, totalBytes, totalBytesSent) {
                this.totalKB = Math.floor(totalBytesSent/1000);
        },
        s3UploadError(e) {
            console.log(e);
            alert('An error occured');
        },
        s3UploadSuccess(filename) {
            this.totalKB = "All";
            this.$store.commit('updateGroupCommentUploadedFilename', filename);
        },
        added(file, response) {
        },
        dispatchPostGroupMessage() {
            this.$refs.myVueDropzone.enable();
            this.$refs.myVueDropzone.removeAllFiles();
            this.loading = true;
            this.$store.dispatch('postGroupMessage', { that: this, page: this.$route.params.id });
        }
    },
    name: "NewGroupPost",
    computed: {
        postGroupMessage: {
            get() {
                console.log("GETTING :"+this.$store.getters.postGroupMessage);
                return this.$store.getters.postGroupMessage;
            },
            set(postGroupMessage) {
                console.log("SETTINGG:"+postGroupMessage);
                this.$store.commit('updateGroupMessage', postGroupMessage);
            }
        },

        ...mapGetters({
            authUser: 'authUser',
        }),

    }

}
</script>

<style>
.dz-upload {
    display: block;
    background-color: red;
    height: 10px;
    width: 0%;
}
 .fade-enter-active, .fade-leave-active {
      transition: opacity 2s
   }
   .fade-enter, .fade-leave-to {
      opacity: 0
   }
</style>