<template>
<div  >
<div class="bg-white">
                        <nav class="flex flex-col tabs sm:flex-row">
                            <button data-target="panel-1" @click="onTabClick" :class="currentTab=='panel-1' ? `border-b-2 border-blue-500` : ``" class="block px-6 py-4 font-medium text-gray-600 text-blue-500 tab hover:text-blue-500 focus:outline-none">
                                Description
                            </button><button data-target="panel-2" :class="currentTab=='panel-2' ? `border-b-2 border-blue-500` : ``" @click="onTabClick" class="block px-6 py-4 tab ext-gray-600 hover:text-blue-500 focus:outline-none">
                                Edit profile
                            </button><button data-target="panel-3" :class="currentTab=='panel-3' ? `border-b-2 border-blue-500` : ``" @click="onTabClick" class="block px-6 py-4 text-gray-600 tab hover:text-blue-500 focus:outline-none">
                                Map
                            </button>
                        </nav>
                    </div>

                    <div id="panels">
                        <ProfileDetails :authUser="authUser" :currentTab="currentTab" :profileData="profileData" :showMoreFriends="showMoreFriends" :tagString="tagString"/>
                        <div class="py-5 panel-2 tab-content" v-if="currentTab=='panel-2'">
<form class="px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md">
    <div class="mb-4">
      <label class="block mb-2 text-sm font-bold text-gray-700" for="tags">
          Tags
      </label>

      <TagInput :addurl="'/api/addtag/'+authUser.id" :removeurl="'/api/removetag/'+authUser.id" :mytags="profileData.tags" :userid="authUser.id" />
    </div>
      <div class="mb-4">
      <label class="block mb-2 text-sm font-bold text-gray-700" for="tags">
          Website URL
      </label>

      <input @keyup="userinput" v-model="profileData.website" class="w-full px-3 py-2 leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="website" type="text" placeholder="http://www.supermod.net">
    </div>
  </form>
                            <div class="flex flex-col items-center pt-4">



                                <div class="p-4 bg-white rounded shadow">
                                        <div class="flex items-center justify-between">

                                                <div>
                                                        <img v-if="authUser" :src="authUser.avatarUri"
                                                        class="object-cover w-8 h-8 rounded-full">
                                                </div>
                                        </div>
                                    </div>
                                    <div class="p-4 bg-white rounded shadow ">
                                        <div class="m-4">
                                            <vue-dropzone @vdropzone-success="added" ref="myVueDropzone" id="dropzone" :options="dropzoneOptions"></vue-dropzone>
                                        </div>
                                    </div>

                            </div>
                              <div class="flex flex-col items-center pt-4">


                                <div class="p-4 bg-white rounded shadow">
                                        <div class="flex items-center justify-between">

                                                <div>
                                                        <img v-if="authUser" :src="authUser.bannerUri"
                                                        class="object-cover w-8 h-8">
                                                </div>
                                        </div>
                                    </div>
                                    <div class="p-4 bg-white">
                                        <div class="m-4">
                                            <vue-dropzone @vdropzone-success="added" ref="myVueBannerDropzone" id="bannerDropzone" :options="bannerDropzoneOptions"></vue-dropzone>
                                        </div>
                                    </div>

                            </div>
                        </div>
                        <div class="py-5 panel-3 tab-content" v-if="currentTab=='panel-3'">
                           <div class="w-2/3 overflow-x-hidden">
                                {{ $t('message.markmap') }}
                            <l-map
                            :center="center"
                            :zoom="zoom"
                            class="w-1/3 overflow-x-hidden map"
                            ref="map"
                            @update:zoom="zoomUpdated"
                            @update:center="centerUpdated" @click="addMarker"
                            >
                                <l-marker v-for="marker, index in markers" v-bind:key="index" :lat-lng="marker" @click="removeMarker(index)"></l-marker>
                            <l-tile-layer
                                :url="url"
                            >
                            </l-tile-layer>
                            </l-map>
                            </div>
                            </div>

                    </div>



</div>
</template>

<script>
import ProfileStory from './ProfileStory';
import ProfileDetails from './ProfileDetails';
import axios from "axios";
import { mapGetters } from 'vuex';
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import "leaflet/dist/images/marker-shadow.png";
import "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/images/marker-icon-2x.png";
import TagInput from "./TagInput";
export default {
    components: {
        vueDropzone: vue2Dropzone,
        LMap,
        LTileLayer,
        LMarker,
        ProfileStory,
         TagInput,
         ProfileDetails
    },
    mounted() {
        axios.get('/api/profiledata/0')
                            .then(res => {
                                    this.profileData = res.data;
                                    this.showMoreFriends = false;
                                    if (this.profileData.friends.length>=6) {
                                        this.showMoreFriends = true;
                                        this.profileData.friends.pop();
                                    }

                            }).catch(error => {
                                console.log(error);
                               alert("A network error occured");
                            })
         axios.get('/api/getmap/0')
                            .then(res => {

                                    this.markers = [
                                        L.latLng(res.data[0], res.data[1])
                                    ];
                            }).catch(error => {
                                console.log(error);
                               alert("A network error occured");
                            })
          delete L.Icon.Default.prototype._getIconUrl;
        L.Icon.Default.mergeOptions({
        iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
        iconUrl: require("leaflet/dist/images/marker-icon.png"),
        shadowUrl: require("leaflet/dist/images/marker-shadow.png")
        });
    },
    data: function () {
    return {

        showMoreFriends : true,
        profileData: [],
        currentTab : 'panel-1',
      dropzoneOptions: {
          url: '/profile-upload/avataruri',
          maxFiles: 1,
          aacceptedFiles: 'image/png,image/jpg,image/gif,image/jpeg',
          maxFilesize: 1000000,
          dictDefaultMessage: this.$t('message.avatarDropfiles')
      },
       bannerDropzoneOptions: {
          url: '/profile-upload/banneruri',
          maxFiles: 1,
          aacceptedFiles: 'image/png,image/jpg,image/gif,image/jpeg',
          maxFilesize: 1000000,
          dictDefaultMessage: this.$t('message.bannerDropfiles')
      },
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
     center: [ 40.75637123, -73.98545321 ],
     zoom: 2,
       markers: [
        L.latLng(40.75637123, -73.98545321)
      ]
    }
    },
    methods: {
        tagString() {
            let str = '';
            if (this.profileData.tags) {
            for (var i = 0; i < this.profileData.tags.length; i ++) {
                if (str.length>0) {
                    str = str + " ";
                }
                console.log("ADDING "+this.profileData.tags[i]);
                str=str+this.profileData.tags[i];
            }
            }
            return str;
        },
        userinput(event) {
            let website = event.target.value;
            axios.post('/api/setinput/website', { value: website })
                            .then(res => {
                            }).catch(error => {
                            })

        },
        removeMarker(index) {
      this.markers.splice(index, 1);
      axios.get('/api/setmap/0/0')
                            .then(res => {
                            }).catch(error => {
                               alert("A network error occured");
                            })
    },
    addMarker(event) {
      this.markers=[];
      this.markers.push(event.latlng);
      axios.get('/api/setmap/'+event.latlng.lat+"/"+event.latlng.lng)
                            .then(res => {

                            }).catch(error => {
                               alert("A network error occured");
                            })
    },
        added(file, response) {
            let responseData = response.status;

            if (responseData == 'OK') {
            this.$store.dispatch('fetchAuthUser');
            } else if (responseData == 'error') {
                alert('A file upload error occured, image size limit is 1MB');
                this.$refs.myVueDropzone.removeAllFiles();
            }
        },
        dispatchPostMessage() {
            this.$refs.myVueDropzone.enable();
            this.$refs.myVueDropzone.removeAllFiles();
            this.$store.dispatch('postMessage');
        },
        zoomUpdated (zoom) {
            this.zoom = zoom;
            console.log(this.markers)
        },
        centerUpdated (center) {
            this.center = center;
        },
        onTabClick(event) {

        let classString = event.target.getAttribute('data-target');
        this.currentTab = classString;
        console.log(this.currentTab);
        },
    },
    name: "Profile",
    computed: {
        postMessage: {
            get() {
                return this.$store.getters.postMessage;
            },
            set(postMessage) {
                this.$store.commit('updateMessage', postMessage);
            }
        },

        ...mapGetters({
            authUser: 'authUser',
        }),

    }

}
</script>

<style>
 .map {
   position: absolute;
   overflow :hidden
 }
 .vue2leaflet-map {
    height: 66% !important;
    width: 66% !important;
}
</style>
