<template>
  <div class="tag-input">
    <div v-for="(tag, index) in mytags" :key="index" class="tag-input__tag">
      <span @click='removeTag(index)'>x</span>
      {{ tag }}
    </div>
    <input ref="that"
      type='text'
      placeholder="Enter a Tag"
      class='tag-input__text'
      @keydown.enter='addTag'
      @keydown.188='addTag'
    />
  </div>
</template>
<script>
import axios from "axios";
  export default {
    data () {
      return {
      }
    },
    props: ["mytags", "userid", "addurl", "removeurl"],
    methods: {
        addTag (event) {
        event.preventDefault()
        var val = this.$refs["that"].value;
        if (val.length > 0) {
            console.log("VAL");
          this.mytags.push(val)
          event.target.value = '';

          axios.post(this.addurl, { value: val })
                            .then(res => {
                            }).catch(error => {
                            })

        }




        },
        removeTag (index) {
             axios.post(this.removeurl, { value: this.mytags[index] })
                            .then(res => {
                            }).catch(error => {
                            })

        this.mytags.splice(index, 1)
        }
    },
    mounted() {
      console.error("TAGINPUT");
      console.log("MYTAGS:"+this.mytags);
       // this.tags = this.mytags;
    }
  }
</script>
<style scoped>
  .tag-input {
    width: 100%;
    border: 1px solid #eee;
    font-size: 0.9em;
    height: 50px;
    box-sizing: border-box;
    padding: 0 10px;
  }

  .tag-input__tag {
    height: 30px;
    float: left;
    margin-right: 10px;
    background-color: #eee;
    margin-top: 10px;
    line-height: 30px;
    padding: 0 5px;
    border-radius: 5px;
  }

  .tag-input__tag > span {
    cursor: pointer;
    opacity: 0.75;
  }

  .tag-input__text {
    border: none;
    outline: none;
    font-size: 0.9em;
    line-height: 50px;
    background: none;
  }
</style>
