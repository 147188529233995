<template>
<div  >
<div class="bg-white">
                        <nav class="flex flex-col tabs sm:flex-row">
                            <button data-target="panel-1" @click="onTabClick" :class="currentTab=='panel-1' ? `border-b-2 border-blue-500` : ``" class="block px-6 py-4 font-medium text-gray-600 text-blue-500 tab hover:text-blue-500 focus:outline-none">
                                Description
                            </button>
                            <button v-if="profileData.mapChanged == 1" data-target="panel-2" :class="currentTab=='panel-2' ? `border-b-2 border-blue-500` : ``" @click="onTabClick" class="block px-6 py-4 text-gray-600 tab hover:text-blue-500 focus:outline-none" >
                                Map
                            </button>
                        </nav>
                    </div>
                    <div id="panels">

                        <ProfileDetails :authUser="authUser" :currentTab="currentTab" :profileData="profileData" :showMoreFriends="showMoreFriends" :tagString="tagString"/>
                        <div class="py-5 panel-3 tab-content" v-if="currentTab=='panel-2'">
                           <div class="w-2/3 overflow-x-hidden">
                            <l-map
                            :center="center"
                            :zoom="zoom"
                            class="w-1/3 overflow-x-hidden map"
                            ref="map"
                            @update:zoom="zoomUpdated"
                            @update:center="centerUpdated"
                            >
                                <l-marker v-for="marker, index in markers" v-bind:key="index" :lat-lng="marker"></l-marker>
                            <l-tile-layer
                                :url="url"
                            >
                            </l-tile-layer>
                            </l-map>
                            </div>
                            </div>

                    </div>



</div>
</template>

<script>
import ProfileDetails from './ProfileDetails';
import axios from "axios";
import { mapGetters } from 'vuex';
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import "leaflet/dist/images/marker-shadow.png";
import "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/images/marker-icon-2x.png";
import TagInput from "./TagInput";
export default {
    components: {
        vueDropzone: vue2Dropzone,
        LMap,
        LTileLayer,
        LMarker,
         TagInput,
         ProfileDetails
    },
    props: ["id"],
    mounted() {
        delete L.Icon.Default.prototype._getIconUrl;
        L.Icon.Default.mergeOptions({
        iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
        iconUrl: require("leaflet/dist/images/marker-icon.png"),
        shadowUrl: require("leaflet/dist/images/marker-shadow.png")
        });
        axios.get('/api/profiledata/'+this.id)
                            .then(res => {
                                    this.profileData = res.data;
                                    this.showMoreFriends = false;
                                    if (this.profileData.friends.length>=6) {
                                        this.showMoreFriends = true;
                                        this.profileData.friends.pop();
                                    }

                            }).catch(error => {
                                console.log(error);
                               alert("A network error occured");
                            })
         axios.get('/api/getmap/'+this.id)
                            .then(res => {

                                    this.markers = [
                                        L.latLng(res.data[0], res.data[1])
                                    ];
                            }).catch(error => {
                                console.log(error);
                               alert("A network error occured");
                            })

    },
    data: function () {
    return {

        showMoreFriends : false,
        profileData: [],
        currentTab : 'panel-1',
      dropzoneOptions: {
          url: '/profile-upload',
          maxFiles: 1,
          aacceptedFiles: 'image/png,image/jpg,image/gif,image/jpeg',
          maxFilesize: 1000000,
          dictDefaultMessage: this.$t('message.avatarDropfiles')
      },
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
     center: [ 40.75637123, -73.98545321 ],
     zoom: 2,
       markers: [
        L.latLng(40.75637123, -73.98545321)
      ]
    }
    },
    methods: {
        tagString() {
            let str = '';
            if (this.profileData.tags) {
            for (var i = 0; i < this.profileData.tags.length; i ++) {
                if (str.length>0) {
                    str = str + " ";
                }
                console.log("ADDING "+this.profileData.tags[i]);
                str=str+this.profileData.tags[i];
            }
            }
            return str;
        },
        userinput(event) {
            let website = event.target.value;
            axios.post('/api/setinput/website', { value: website })
                            .then(res => {
                            }).catch(error => {
                            })

        },
        removeMarker(index) {
      this.markers.splice(index, 1);
      axios.get('/api/setmap/0/0')
                            .then(res => {
                            }).catch(error => {
                               alert("A network error occured");
                            })
    },
    addMarker(event) {
      this.markers=[];
      this.markers.push(event.latlng);
      axios.get('/api/setmap/'+event.latlng.lat+"/"+event.latlng.lng)
                            .then(res => {

                            }).catch(error => {
                               alert("A network error occured");
                            })
    },
        added(file, response) {
            let responseData = response.status;

            if (responseData == 'OK') {
            this.$store.dispatch('fetchAuthUser');
            } else if (responseData == 'error') {
                alert('A file upload error occured, image size limit is 1MB');
                this.$refs.myVueDropzone.removeAllFiles();
            }
        },
        dispatchPostMessage() {
            this.$refs.myVueDropzone.enable();
            this.$refs.myVueDropzone.removeAllFiles();
            this.$store.dispatch('postMessage');
        },
        zoomUpdated (zoom) {
            this.zoom = zoom;
            console.log(this.markers)
        },
        centerUpdated (center) {
            this.center = center;
        },
        onTabClick(event) {

        let classString = event.target.getAttribute('data-target');
        this.currentTab = classString;
        console.log(this.currentTab);
        },
    },
    name: "Profile",
    computed: {
        postMessage: {
            get() {
                return this.$store.getters.postMessage;
            },
            set(postMessage) {
                this.$store.commit('updateMessage', postMessage);
            }
        },

        ...mapGetters({
            authUser: 'authUser',
        }),

    }

}
</script>

<style>
 .map {
   position: absolute;
   overflow :hidden
 }
 .vue2leaflet-map {
    height: 66% !important;
    width: 66% !important;
}
</style>
