<template>

        <div class="p-4 mt-4 bg-white rounded shadow ">

 <div v-show="loading" class="w-full max-w-xs mt-32 mb-12">
<div class="flex items-center justify-center ">
    <div class="w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin"></div>
    </div>
</div>

        <div v-if="!loading">
                <div>
                    <p>{{ $t('message.youareowner') }}</p>
                    <p v-if="groupDetails.numberOfJoinRequests > 0">{{ $t('message.youhave') }} {{ groupDetails.numberOfJoinRequests }} {{ $t('message.joinrequests') }}.</p>
                    <p v-if="groupDetails.numberOfJoinRequests > 0" class="text-blue-700">
                        <router-link to="/joinrequests">
                        {{ $t('message.clickhere') }}
                        </router-link>
                    </p>
                </div>
            </div>
        </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    components: {
    },
    data: function () {
    return {
        postMessage : '',
      loading : false,
    }
    },
    methods: {

        dispatchJoinRequest() {
            this.$store.dispatch('postGroupJoinRequest', { postMessage: this.postMessage, groupid: this.$route.params.id });
        }
    },
    name: "NewJoinRequest",
    computed: {


        ...mapGetters({
            authUser: 'authUser',
            groupDetails: 'groupDetails',
        }),

    }

}
</script>

<style>
.dz-upload {
    display: block;
    background-color: red;
    height: 10px;
    width: 0%;
}
 .fade-enter-active, .fade-leave-active {
      transition: opacity 2s
   }
   .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0
   }
</style>