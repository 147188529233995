<template>

        <div class="p-4 bg-white rounded shadow ">

                        <div class="mx-4 mb-4">
                          {{ $t('message.currentimage') }}
                        </div>
                        <div>
                            <div v-if="photoType=='mp4'">
                    <vue-plyr >
                        <video
                            controls
                            crossorigin
                            playsinline
                        >
                            <source
                            :src="photo"
                            type="video/mp4"
                            />
                        </video>
                        </vue-plyr>
                </div>
                <div v-else>
                    <img :src="photo" class="w-full">
                </div>
                        </div>
                        <div class="mx-4 mt-4">
                           {{ $t('message.newimage') }}
                        </div>


                <div class="m-4">
                    <vue-dropzone  v-on:vdropzone-upload-progress="sending" v-on:vdropzone-s3-upload-error="s3UploadError"
        v-on:vdropzone-s3-upload-success="s3UploadSuccess" :awss3="awss3" @vdropzone-success="added" ref="myVueDropzone" id="dropzone" :options="dropzoneOptions"></vue-dropzone>
                </div>
                <div>
                    {{ $t('message.totalkb') }}: {{ totalKB }} Kilobytes
                </div>

        </div>
</template>

<script>
import axios from "axios"
import { mapGetters } from 'vuex';
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
export default {
    components: {
        vueDropzone: vue2Dropzone
    },
    props: ["groupDetail"],
    data: function () {
    return {
      photoType : '',
      photo : '',
      totalKB: 0,
      dropzoneOptions: {
          url: '/file-upload',
          maxFiles: 1,
          acceptedFiles: 'image/png,image/jpg,image/gif,image/jpeg,video/mp4',
          dictDefaultMessage: this.$t('message.dropzone'),
      },
      awss3: {
      signingURL: '/signature', //Where you will get signed url
      headers: {},
      params : {},
      sendFileToServer :false //If you want to upload file to your server along with s3
        }
    }
    },
    mounted() {
        console.log(this.groupDetail);
        this.photoType = this.groupDetail.photoType;
        this.photo = this.groupDetail.photo;
    },
    methods: {
        sending(file, totalBytes, totalBytesSent) {
                this.totalKB = Math.floor(totalBytesSent/1000);
        },
        s3UploadError(e) {
            console.log(e);
            alert('An error occured');
        },
        s3UploadSuccess(filename) {
            this.totalKB = "All";
             axios.post('/api/postgroupimage/'+this.$route.params.id, { uploadedFilename : filename })
            .then(res => {
                if (res.data.status=='error') {
                alert(res.data.details);
                } else {
                        this.photo = res.data.photo;
                        this.photoType = res.data.photoType;
                }

            }).catch(error => {
               console.error(error);
            })
        },
        added(file, response) {
        },
    },
    name: "GroupImage",
    computed: {

        ...mapGetters({
            authUser: 'authUser',
        }),

    }

}
</script>

<style>
.dz-upload {
    display: block;
    background-color: red;
    height: 10px;
    width: 0%;
}
 .fade-enter-active, .fade-leave-active {
      transition: opacity 2s
   }
   .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0
   }
</style>